
:root {
  --color-dark: hsl(293, 36%, 5%);
  --color-light: hsl(0, 0%, 95%);
  --color-highlight: hsl(320, 89%, 44%);
  --color-main: hsl(320, 94%, 43%);
  --color-secondary: hsl(320, 97%, 39%);
  --color-faded-75: hsl(320, 97%, 39%, 75%);
  --color-faded-50: hsla(320, 97%, 39%, 0.5);
  --color-faded-25: hsla(320, 97%, 39%, 0.25);
  --color-faded-black-25: hsla(0, 0%, 0%, 0.6);
}

html, body {
  overflow: hidden; 
  width: 100%;
  height: 100%;
  margin:0; 
  padding:0;
}



#renderCanvas {
  width: 100%;
  height: 100%;
  touch-action: none;
}

#video{
  position: "absolute";
  z-index: 11;
}

#fps {
  position: absolute;
  background-color: black;
  border: 2px solid red;
  text-align: center;
  font-size: 16px;
  color: white;
  top: 15px;
  right: 10px;
  width: 60px;
  height: 20px;
}

#outer {
  width: 100%;
  /* Firefox */
  display: -moz-box;
  -moz-box-pack: center;
  -moz-box-align: center;
  /* Safari and Chrome */
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  /* W3C */
  display: box;
  box-pack: center;
  box-align: center;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 11; /* Sit on top */
  /* left: 5%; */
  top: 10%;
  width: 90%; /* Full width */
 /*  height: 80%; */ /* Full height */
  max-height: 80%;
  max-width: 1100px;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.6);/* var(--color-faded-25); */ /* Fallback color */
  border-radius: 5px; 
}

/* Modal Content/Box */
.modal-content {
  color: whitesmoke;
  display: block;
  background-color: transparent;
  margin: 3% 3%;
  width: auto;
  font-family: 'Rubik';
  letter-spacing: 0px;
  font-size: calc(12px + 0.5vw);
  font-weight: 400;
  text-align: justify;
}

.text-title {
  font-size: calc(22px + 0.5vw);
  font-weight: 600;
}

.blackout {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 0; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.0); /* Black w/ opacity */
}
#dropdownbutton{
  display: none;
}
.dropbtn {
  background-color: #2b2b2b79;
  color: white;
  padding-top: 8px;
  padding-bottom: 4px;
  width: 18vw;
  min-width: 160px;
  height: 5vh;
  min-height: 25px;
  /* font-size: 16px; */
  font-size: calc(12px + 0.5vw);
  border: none;
  border-bottom: 2px solid var(--color-main);
  cursor: pointer;
}

.dropdown {
  z-index: 11;
  position: absolute;
  display: inline-block;
  top: 0;
}


.dropdown-content {
  display: none;
  position: absolute;
  background-color: #2b2b2b79;
  width: 18vw;
  min-width: 160px;
  max-height: 50vh;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-bottom: 1px solid var(--color-light);
  overflow-y: scroll
}

.dropdown-content a {
  color: var(--color-light);
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid var(--color-light);
  display: block;
}

.dropdown-content a:hover {background-color: var(--color-faded-50)}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  color: var(--color-main);
  
}

#title_box {
  display: block; /* Hidden by default */
  position: absolute; /* Stay in place */
  z-index: 11; /* Sit on top */
  font-family: 'Rubik';
  white-space: nowrap;
  letter-spacing: 0px;
  top:30%;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  text-align: center;
  color: #fafafa;
}

#title {
  font-size: 6vw;/* 43px; */
  /* font-weight: 900; */
  font-weight: 500;
  text-shadow: 1px 1px #00000085; 
  margin: 10px;
  touch-action: none;
  pointer-events:none;
}

#subtitle {
  font-size: 2vw;
  font-weight: normal;
  text-shadow: 1px 1px #00000085; 
  margin: 10px;
  touch-action: none;
  pointer-events:none;
}

button.btn{
  font-size: 2vw;
  font-family: 'Rubik';
  background-color: #fafafaa6;
  color: black;
  border: none;
  border-radius: 3px;
  padding: 1vw 2vw;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  margin: 1vw 2vw;
  -webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}

button.btn-1:hover{
  background-color: #000000a6;
  border: 2px solid white;
  color: white;
  -webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}

p { margin:0 }

#helperText{
  font-family: 'Rubik';
  white-space: nowrap;
  position:absolute;
  font-size: 1.5vw;
  color: #fafafa;
  text-shadow: 0px 0px 3px #00000036; 
  top:45%;
  width: 100%; /* Full width */
  height: 100%; 
  text-align: center;
  font-weight: normal;
}

#helper{
  display: none;
  touch-action: none;
  pointer-events:none;
} 
#pot{
  bottom:40%;
  width:10vw;
  position:absolute;
  -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1))
                  drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1)) 
          drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1));
  -webkit-animation: ease-in-out infinite;
  -webkit-animation-name: run;
  -webkit-animation-duration: 2s;
  -moz-animation: ease-in-out infinite;
  -moz-animation-name: run;
  -moz-animation-duration: 2s;
  -o-animation: ease-in-out infinite;
  -o-animation-name: run;
  -o-animation-duration: 2s;
  }     
#potImage{
    width:9vw;
    height:9vw;
    max-width: 100px;
    max-height: 100px;
    min-width: 50px;
    min-height: 50px;
  }  
  
@-webkit-keyframes run {
  0% { left: 0%;}
  50%{ left : calc(90%);}
  100%{ left: 0%;}
}

@keyframes run {
  0% { left: 40%;}
  50%{ left : calc(50%);}
  100%{ left: 40%;}
}
/* #mapButton {
  z-index: 11;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #2b2b2b79;
  color: white;
  padding-top: 8px;
  padding-bottom: 4px;
  width: 100%;
  min-width: 160px;
  height: 5vh;
  min-height: 25px;
  font-size: calc(12px + 0.5vw);
  border: none;
  border-bottom: 2px solid var(--color-main);
  cursor: pointer;
  display: none;
} */

#mapbtn{
  z-index: 11;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #2b2b2b79;
  color: white;
  padding-top: 8px;
  padding-bottom: 4px;
  width: 100%;
  min-width: 160px;
  height: 5vh;
  min-height: 25px;
  /* font-size: 16px; */
  font-size: calc(12px + 0.5vw);
  border: none;
  border-bottom: 2px solid var(--color-main);
  cursor: pointer;
  display: none;
}
#mapbtn:hover + #map{
  display: block;
}
#map:hover{
  display: block;
}

#map{
  position: absolute; 
  bottom: 5vh;
  width: 100vw;
  min-width: 160px;
  max-width: 50vh; 
/*   left: calc(12px + 0.5vw); */
  /* height: 19vw;
  min-height: 170px; */
  /* max-height: 50vh; */
  background-color: #2b2b2b79;
  display: none;
  left: 50%;
  transform: translate(-50%, 0%);

}
.series .room:hover {
  fill: #ec008c;
  opacity: 1;
  stroke-width:15;
}

#triangle{
  position: absolute;
  filter: invert(68%) sepia(43%) saturate(5207%) hue-rotate(270deg) brightness(145%) contrast(170%);
}

.floorbtn{
  z-index: 11;
  background-color: #1d1d1d79;
  color: white;
  padding: 4px;
  /* font-size: 16px; */
  font-size: calc(9px + 0.4vw);
  border: none;
  cursor: pointer;
}